import { Suspense } from 'react'
import ScrollToTop from './components/ScrollToTop'
import Loader from './components/Loader'
import Routes from './routes/Routes'
import { BrowserRouter } from 'react-router-dom'
import { styled } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import ThemeProvider from './theme'
import { AuthContextProvider } from './context/AuthContext'
import { HelmetProvider } from 'react-helmet-async'
import '../src/css/App.css'

// alert notification
// alert notification
const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.notistack-MuiContent-success {
    background-color: #1f292b;
  }
  ,
  &.notistack-MuiContent {
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`

const App = () => {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <HelmetProvider>
          <ThemeProvider>
            <ScrollToTop />
            <StyledSnackbarProvider
              // iconVariant={{
              //   success: <img src={success} alt="success" />,
              //   error: <img src={error} alt="error" />,
              //   warning: '⚠️',
              //   info: 'ℹ️',
              // }}
              hideIconVariant
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Suspense fallback={<Loader />}>
                <Routes />
              </Suspense>
            </StyledSnackbarProvider>
          </ThemeProvider>
        </HelmetProvider>
      </BrowserRouter>
    </AuthContextProvider>
  )
}

export default App
